/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

const Dropdown = (props) => {
  const {
    items, selectedItem, selectedItemViewTransform,
    listItemViewTransform, listItemKeyTransform, listItemOnClickTransform,
    className,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItemKey, setSelectedItemKey] = useState(-1);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div
      className={`dropdown ${dropdownOpen ? 'is-active' : ''} ${className}`}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) { setDropdownOpen(false); }
      }}
    >
      <div className="dropdown-trigger">
        <button
          type="button"
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={toggleDropdown}
        >
          {selectedItem && selectedItemViewTransform && selectedItemViewTransform(selectedItem)}
          <span className="icon is-small pl-4">
            <i className="fa fa-angle-down" aria-hidden="true" />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {items.map((item) => (
            <a
              href="#"
              key={listItemKeyTransform && listItemKeyTransform(item)}
              className={`dropdown-item ${listItemKeyTransform && listItemKeyTransform(item) === selectedItemKey ? 'is-active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                toggleDropdown();
                if (listItemKeyTransform) {
                  setSelectedItemKey(listItemKeyTransform(item));
                }
                listItemOnClickTransform(item);
              }}
            >
              {listItemViewTransform(item)}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
