/* eslint-disable react/prop-types */
import React from 'react';
import './styles.css';

const Spinner = (props) => {
  const { under } = props;
  return (
    <div className={`${under ? 'lds-spinner-under' : 'lds-spinner-center'} lds-spinner`}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
