import axios from 'axios';
import ImageBlobReduce from 'image-blob-reduce/dist/image-blob-reduce';
import { useEffect, useState } from 'react';
import cloudsImg from './saturn_mascot.png';

export const PLACEHOLDER_IMAGE = cloudsImg;

const replaceHistoryLastScrollPos = (lastScrollPosition) => {
  window.history.replaceState({ ...window.history.state, lastScrollPosition }, '', window.location.href);
};

export const saveProductsScrollPosition = () => {
  replaceHistoryLastScrollPos(window.scrollY);
};

export const scrollToLastSavedPosition = () => {
  if (typeof window.history.state.lastScrollPosition === 'number') {
    window.scrollTo(0, window.history.state.lastScrollPosition);
    delete window.history.state.lastScrollPosition;
    replaceHistoryLastScrollPos(null);
  }
};

export const getNumPagesFromHeaders = (headers, numItemsPerPage) => Math.ceil(parseInt(headers['x-total-count'], 10) / numItemsPerPage);

export const isFunction = ((fn) => fn && typeof fn === 'function');
export const remove = (items, index) => [...items.slice(0, index),
  ...items.slice(index + 1, items.length)];

export const createNewId = () => Math.random().toString(36).substring(2) + Date.now().toString(36);

export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return isIntersecting;
};

export const resolveStock = (product, selectedVariant, selectedColorVariant, cart) => {
  let stock = null;
  if (selectedVariant && selectedVariant.stock != null) {
    stock = selectedVariant.stock;
  }
  if (selectedColorVariant && selectedColorVariant.stock != null) {
    if (stock == null) stock = selectedColorVariant.stock;
    else stock = Math.min(stock, selectedColorVariant.stock);
  }
  if (stock == null) {
    if (product && product.stock) stock = product.stock;
    else stock = 0;
  }
  if (stock > 0) {
    const cartItems = cart.filter((c) => c.id === product.id);
    cartItems.forEach((c) => {
      // Deduct availability for each cart item if any of the
      // following is true:
      // (1) There is a stock specified in the variant and the variant matches
      // the item in cart
      // (2) There is a stock specified in the color variant and the color
      // variant matches the item in cart
      // (3) This product may or may not have a variant or color variant but
      // does not have a stock specified in either of those
      //
      // The last two are cases where the stock is taken from the product and
      // the first two cases are where the stock is taken from the variant or
      // color variant
      if ((selectedVariant && selectedVariant.stock != null
        && c.selectedVariant.name === selectedVariant.name)
        || (selectedColorVariant && selectedColorVariant.stock != null
          && c.selectedColorVariant.colorId === selectedColorVariant.colorId)
        || ((!selectedVariant || selectedVariant.stock == null)
         && (!selectedColorVariant || selectedColorVariant.stock == null))) {
        stock -= c.count;
      }
    });
  }
  stock = stock > 0 ? stock : 0;
  return stock;
};

const imageReducer = new ImageBlobReduce({
  pica: ImageBlobReduce.pica({ features: ['js', 'wasm', 'ww'] }),
});

export const reduceImage = (file) => new Promise((resolve, reject) => {
  // console.log(`initial file size: ${file.size / 1024 / 1024} MB`);
  const fr = new FileReader();
  fr.onload = (e) => resolve(e.target.result);
  fr.onabort = (e) => reject(e);
  fr.onerror = () => reject(fr.error);
  imageReducer
    .toBlob(
      file,
      {
        max: 900,
        unsharpAmount: 80,
        unsharpRadius: 0.6,
        unsharpThreshold: 2,
      },
    ).then((blob) => {
      // console.log(`reduced file size: ${blob.size / 1024 / 1024} MB`);
      fr.readAsDataURL(blob);
    });
});

export const reduceImages = (files, onSuccess, onError) => {
  if (!files || files.length <= 0) {
    onSuccess([]);
    return;
  }
  const reduceFilePromises = Array.prototype.map.call(
    files,
    (file) => reduceImage(file),
  );
  Promise.all(reduceFilePromises).then((images) => onSuccess(images))
    .catch((e) => isFunction(onError) && onError(e));
};

export const reduceDraggedImages = (dataTransfer, multiple, onSuccess, onError) => {
  const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
  let { files } = dataTransfer;
  if (files && files.length && files.length > 0) {
    files = Array.prototype.filter.call(files, (file) => validTypes.includes(file.type));
    if (files.length > 0) {
      reduceImages(multiple ? files : [files[0]], onSuccess, onError);
    }
  }
};

export const emptyPageClass = 'is-size-7 has-text-weight-light';

export const isNotDesktop = () => window.innerWidth < 1024;
export const isMobile = () => window.innerWidth < 769;
// eslint-disable-next-line no-nested-ternary
export const backendUrl = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL
  : `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

// eslint-disable-next-line no-nested-ternary
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL
  : `${backendUrl}/api/v1`;

export const toFullImgUrl = (src) => {
  if (src) {
    // src is in URL form
    if (src.includes(':')) return src;
    // src is relative path
    return `${backendUrl}/${src}`;
  }
  return PLACEHOLDER_IMAGE;
};

export const fetchCategoriesForProduct = (product) => Promise.all(product.categories.map(
  async (categoryId) => {
    const { data: category } = await axios.get(`${apiBaseUrl}/categories/${categoryId}`);
    return category;
  },
));
