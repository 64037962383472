/* eslint-disable react/prop-types */
import React from 'react';
import { asPriceString, resolveProductPrice } from '@garaiecommerce/common';

const ProductPrice = (props) => {
  const {
    product, variant, colorVariant, categories,
  } = props;
  const { price, salePrice } = resolveProductPrice(product, variant, colorVariant, categories);

  return (
    <span>
      <span
        style={salePrice ? { textDecoration: 'line-through', fontSize: '0.9rem' } : {}}
      >
        {' '}
        $
        {asPriceString(price)}
      </span>
      {
        salePrice && ` $${asPriceString(salePrice)}`
      }
    </span>
  );
};

export default ProductPrice;
