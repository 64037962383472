/* eslint-disable react/prop-types */
import React from 'react';

const PriceTallyRow = (props) => {
  const { label, value } = props;

  return (
    <div className="columns is-mobile" style={{ textAlign: 'right' }}>
      <div className="column is-7-mobile is-8-tablet is-10-desktop is-pulled-right">
        {`${label}:`}
      </div>
      <div className="column">
        {value}
      </div>
    </div>
  );
};

export default PriceTallyRow;
