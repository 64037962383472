/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

const MeasurementForm = (props) => {
  const {
    onSave, onCancel, measurement,
  } = props;
  const [name, setName] = useState('');
  const [nameEmpty, setNameEmpty] = useState(false);
  const [value, setValue] = useState('');
  const [valueEmpty, setValueEmpty] = useState(false);
  useEffect(() => {
    if (measurement) {
      setName(measurement.name);
      setValue(measurement.value);
    }
  }, []);
  const handleSave = () => {
    let hasError = false;
    if (!name) {
      setNameEmpty(true);
      hasError = true;
    }
    if (!value) {
      setValueEmpty(true);
      hasError = true;
    }
    if (hasError) {
      return;
    }
    onSave({ name: name.trim(), value: value.trim() });
  };
  const handleNameInput = (e) => {
    setNameEmpty(false);
    setName(e.target.value);
  };
  const handleValueInput = (e) => {
    setValueEmpty(false);
    setValue(e.target.value);
  };
  return (
    <div className="py-2 px-4">
      <div className="field">
        <label className="label is-size-7">Type: </label>
        <div className="control">
          <input
            className="input is-size-7"
            type="text"
            name="measurementname"
            placeholder="Enter Measurement Type (e.g. weight, height, diameter etc.)"
            onChange={handleNameInput}
            value={name}
          />
        </div>
        {nameEmpty && <p className="help is-danger">name cannot be empty</p>}
      </div>
      <div className="field">
        <label className="label is-size-7">Value: </label>
        <div className="control">
          <input
            className="input is-size-7"
            type="text"
            name="measurementvalue"
            placeholder="Enter value including units (e.g. 20cm, 5g  etc.)"
            onChange={handleValueInput}
            value={value}
          />
        </div>
        {valueEmpty && <p className="help is-danger">value cannot be empty</p>}
      </div>
      <div className="field is-grouped pt-2">
        <div className="control">
          <button type="button" onClick={handleSave} className="button is-link">Save</button>
        </div>
        <div className="control">
          <button type="button" onClick={onCancel} className="button is-link is-light">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default MeasurementForm;
