/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { StoreContext } from '../context/StoreContext';
import { createNewId, resolveStock, saveProductsScrollPosition } from '../utils';

const AddToCart = (props) => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(StoreContext);
  const [addedToCart, setAddedToCart] = useState(false);
  const {
    product, selectedVariant, selectedColorVariant, openProductIfHasVariant,
  } = props;
  const { cart } = state;
  const openProduct = (openProductIfHasVariant
    && ((product.variants && product.variants.length > 0)
      || (product.colorVariants && product.colorVariants.length > 0)));
  const stock = openProduct
    ? 0 : resolveStock(product, selectedVariant, selectedColorVariant, cart);
  const handleClick = (e) => {
    e.stopPropagation();
    if (openProduct) {
      saveProductsScrollPosition();
      navigate(`/view-product/${product.id}`);
      return;
    }
    const payload = {
      cartItemId: createNewId(),
      id: product.id,
      name: product.name,
    };
    if (selectedVariant) {
      payload.selectedVariant = { id: selectedVariant.id, name: selectedVariant.name };
    }
    if (selectedColorVariant) {
      payload.selectedColorVariant = {
        id: selectedColorVariant.id, colorId: selectedColorVariant.colorId,
      };
    }
    dispatch({ type: 'ADD_TO_CART', payload });
    setAddedToCart(true);
    setTimeout(() => setAddedToCart(false), 1000);
  };
  return (
    <>
      <button
        type="button"
        className="button is-small is-primary"
        disabled={!openProduct && (!stock || stock <= 0 || !product.visible)}
        onClick={handleClick}
      >
        {!openProduct && (stock > 0
          ? 'Add to Cart' : 'Out of Stock')}
        {openProduct && 'View Options'}
      </button>
      {addedToCart && <span className="is-family-secondary is-size-7 has-text-weight-light"> added!</span>}
    </>
  );
};

export default AddToCart;
