/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { StoreContext } from '../context/StoreContext';
import { backendUrl } from '../utils';

const ChangePassword = () => {
  const { state } = useContext(StoreContext);
  const { user } = state;
  const [password, setPassword] = useState('');
  const [reEnterPassword, setReEnterPassword] = useState('');

  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const [reEnterPasswordEmpty, setReEnterPasswordEmpty] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const [submitError, setSubmitError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const handlePasswordInput = (e) => {
    e.preventDefault();
    if (e.target.value) {
      if (passwordEmpty) setPasswordEmpty(false);
      if (!passwordsMatch && e.target.value === reEnterPassword) setPasswordsMatch(true);
    }
    setPassword(e.target.value);
  };
  const handleReEnterPasswordInput = (e) => {
    e.preventDefault();
    if (e.target.value) {
      if (reEnterPasswordEmpty) setReEnterPasswordEmpty(false);
      if (!passwordsMatch && e.target.value === password) setPasswordsMatch(true);
    }
    setReEnterPassword(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;
    if (!password) {
      setPasswordEmpty(true);
      hasError = true;
    }
    if (!reEnterPassword) {
      setReEnterPasswordEmpty(true);
      hasError = true;
    }
    if (password !== reEnterPassword) {
      setPasswordsMatch(false);
      hasError = true;
    }
    if (hasError) return;
    axios.post(`${backendUrl}/changepassword`, { id: user, password })
      .then(() => {
        setSubmitted(true);
      })
      .catch((err) => {
        setSubmitError(err.response.data.message);
      });
  };
  const handleKeyPress = ((e) => {
    if (e.key === 'Enter') handleSubmit(e);
  });
  return submitted ? <div>Successfully changed password!</div> : (
    <form className="" onSubmit={handleSubmit}>
      <div className="columns is-mobile is-centered form-subsection">
        <div className="column">
          <div className="field">
            <label className="label">New Password: </label>
            <div className="control">
              <input
                className="input"
                type="password"
                name="password"
                value={password}
                onKeyPress={handleKeyPress}
                onChange={handlePasswordInput}
              />
            </div>
            {passwordEmpty && <p className="help is-danger">password cannot be empty</p>}
          </div>
          <div className="field">
            <label className="label">Re-enter New Password: </label>
            <div className="control">
              <input
                className="input"
                type="password"
                name="repassword"
                value={reEnterPassword}
                onKeyPress={handleKeyPress}
                onChange={handleReEnterPasswordInput}
              />
            </div>
            {reEnterPasswordEmpty && <p className="help is-danger">need to re-enter password</p>}
            {!passwordsMatch && <p className="help is-danger">passwords need to match</p>}
          </div>
          {submitError && <p className="pt-4 help is-danger">{submitError}</p>}
          <div className="pt-5 field is-pulled-right">
            <div className="control">
              <button type="button" onClick={handleSubmit} className="button is-link">Update</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChangePassword;
