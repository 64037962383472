import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../context/StoreContext';

const SnackBar = () => {
  const { state, dispatch } = useContext(StoreContext);
  const { showSnackBar } = state;
  const { snackBarText } = state;

  useEffect(() => {
    if (showSnackBar) {
      // hide snack bar after a few seconds
      setTimeout(() => {
        dispatch({ type: 'HIDE_SNACK_BAR' });
      }, 5000);
    }
  }, [showSnackBar]);

  return (
    <div
      id="snackbar"
      className={showSnackBar ? 'show' : ''}
    >
      {snackBarText}
    </div>
  );
};

export default SnackBar;
