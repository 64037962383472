/* eslint-disable react/prop-types */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Order from './Order';
import { apiBaseUrl, getNumPagesFromHeaders } from '../utils';
import Pagination from './Pagination';

const ORDERS_PER_PAGE = 10;

const OrdersTab = (props) => {
  const { userId } = props;
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState();

  const [reloadRequested, setReloadRequested] = useState(false);

  const [numPages, setNumPages] = useState(1);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const offset = ORDERS_PER_PAGE * currentPageIndex;

  const loadTab = async () => {
    const { headers, data: fetchedOrders } = await
    // eslint-disable-next-line react/destructuring-assignment
    axios.get(`${apiBaseUrl}/orders?${Object.keys(props).map((key) => `${key}=${props[key]}`).join('&')}&offset=${offset}&limit=${ORDERS_PER_PAGE}`);
    let newNumPages = 0;
    if (fetchedOrders) {
      setOrders(fetchedOrders);
      newNumPages = getNumPagesFromHeaders(headers, ORDERS_PER_PAGE);
      setNumPages(newNumPages);
    }
  };

  useEffect(loadTab, [props, currentPageIndex]);

  useEffect(async () => {
    if (reloadRequested) await loadTab();
    setReloadRequested(false);
  }, [reloadRequested]);

  const handleOrderClicked = (order) => {
    if (selectedOrder && (order.id === selectedOrder.id)) {
      setSelectedOrder(null);
    } else {
      setSelectedOrder(order);
    }
  };

  return (
    <div className="px-3">
      {
        orders.map((order) => (
          <Order
            key={order.id}
            order={order}
            filteredForUser={!!userId}
            selected={selectedOrder && (order.id === selectedOrder.id)}
            onOrderClick={handleOrderClicked}
            onReload={() => setReloadRequested(true)}
          />
        ))
      }
      <Pagination
        numPages={numPages}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
      />
    </div>
  );
};

export default OrdersTab;
