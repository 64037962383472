/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useState } from 'reinspect';
import { reduceDraggedImages, toFullImgUrl } from '../utils';
import ImageInput from './ImageInput';
import Spinner from './Spinner';

const UploadableImage = (props) => {
  const {
    initialSrc, onChangeSrc, onDelete, className, alt,
  } = props;
  const [src, setSrc] = useState(initialSrc);
  const [showSpinner, setShowSpinner] = useState(false);
  useEffect(() => {
    setSrc(initialSrc);
  }, [initialSrc]);

  const onImagesProcessed = (sources) => {
    const newSrc = sources[0];
    setSrc(newSrc);
    if (onChangeSrc && typeof onChangeSrc === 'function') onChangeSrc(newSrc);
    setShowSpinner(false);
  };

  const [dragInProgress, setDragInProgress] = useState(false);
  const handleDragInProgress = (e) => {
    e.preventDefault();
    setDragInProgress(true);
  };

  const handleDragDone = (e) => {
    e.preventDefault();
    setDragInProgress(false);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    setDragInProgress(false);
    setShowSpinner(true);
    reduceDraggedImages(e.dataTransfer, false /* multiple */, onImagesProcessed);
  };

  const handleDelete = (e) => { e.stopPropagation(); onDelete(); };
  return (
    <ImageInput
      onImagesProcessed={onImagesProcessed}
      onStartProcessing={() => setShowSpinner(true)}
      onError={() => setShowSpinner(false)}
    >
      <figure
        className={`image is-editable is-5by4 ${className} ${dragInProgress ? 'has-background-primary-light' : ''}`}
        onDragOver={handleDragInProgress}
        onDragEnter={handleDragInProgress}
        onDragEnd={handleDragDone}
        onDragLeave={handleDragDone}
        onDrop={handleFileDrop}
      >
        <img src={toFullImgUrl(src)} alt={alt} />
        <i type="file" accept="image/*" className={`${showSpinner ? '' : 'fa fa-camera'}`} />
        { onDelete && typeof onDelete === 'function'
          && (
          <i
            role="button"
            className="fa fa-trash"
            onClick={handleDelete}
            onKeyPress={handleDelete}
            tabIndex={0}
            aria-label="delete"
          />
          )}
        {showSpinner && <Spinner />}
      </figure>
    </ImageInput>
  );
};

export default UploadableImage;
