const { canada } = require("./supported_countries/canada");
const { unitedStates } = require("./supported_countries/unitedStates");

module.exports.DEFAULT_CURRENCY = 'CAD';
const SHIPPING_COST = 19.99;
const MIN_SUBTOTAL_FOR_FREE_SHIPPING = 30;
module.exports.MIN_SUBTOTAL_FOR_FREE_SHIPPING = MIN_SUBTOTAL_FOR_FREE_SHIPPING;

// Need to always use ISO 3166 codes
module.exports.SUPPORTED_COUNTRIES = [
  canada, unitedStates,
];

// TODO make these configurable based on env var
module.exports.MERCHANT_ADDRESS = '202 Poplin St, Ottawa, ON, K4M 0G9';
module.exports.MERCHANT_DIVISION = this.SUPPORTED_COUNTRIES[0].supportedDivisions[0];

const MAX_SHORT_NAME_SIZE = 30;
module.exports.getShortOrderName = (order) => {
  let shortOrderName = order.items.map((item) => item.name).join(',');
  if (shortOrderName.length > MAX_SHORT_NAME_SIZE) {
    shortOrderName = `${shortOrderName.substring(0, MAX_SHORT_NAME_SIZE)}...`;
  }
  return shortOrderName;
}

module.exports.resolveShipping = (subtotal) => {
  if (subtotal >= MIN_SUBTOTAL_FOR_FREE_SHIPPING) {
    return 0;
  } else {
    return SHIPPING_COST;
  }
};

module.exports.resolveSubtotalAfterTaxes = (subtotalCost, countryDivision) => {
  let subtotalAfterTaxes = subtotalCost;
  if (countryDivision.taxItems) {
    countryDivision.taxItems.forEach((taxItem) => {
      subtotalAfterTaxes += (parseFloat(taxItem.percentage) / 100) * subtotalCost;
    });
  }
  return subtotalAfterTaxes;
};

module.exports.asPrice = (number) => (Math.round(number * 100) / 100).toFixed(2);
module.exports.asPriceString = (number) => Number(number).toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2 });

module.exports.resolveOrderSubtotal = (order) => {
  const prices = order.items.map((item) => item.count * (item.price.salePrice || item.price.price));
  const subtotal = prices.reduce((sum, price) => sum + price, 0);
  return subtotal;
};

module.exports.resolveOrderItemName = (item) => {
  let { name } = item;
  if (item.selectedVariant) {
    name = `${name}, ${item.selectedVariant.name}`;
  }
  if (item.selectedColorVariant) {
    name = `${name}, ${item.selectedColorVariant.colorId}`;
  }
  return name;
};

module.exports.resolveProductPrice = (product, variant, colorVariant, categories) => {
  let { price, salePrice } = product;
  if (categories) {
    const categoryDiscounts = categories.map(
      (category) => (category.discount ? category.discount : 0),
    );
    const discountFromCategory = (((salePrice || price) * Math.max(...categoryDiscounts)) / 100);
    if (salePrice) salePrice -= discountFromCategory;
    else salePrice = price - discountFromCategory;
  }
  if (variant && variant.additionalCost) {
    price += variant.additionalCost;
    if (salePrice) salePrice += variant.additionalCost;
  }
  if (colorVariant && colorVariant.additionalCost) {
    price += colorVariant.additionalCost;
    if (salePrice) salePrice += colorVariant.additionalCost;
  }
  if (salePrice) salePrice = Math.max(salePrice, 0);
  if (salePrice >= price) salePrice = null;
  return { price, salePrice };
};
