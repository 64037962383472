/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { StoreContext } from '../context/StoreContext';
import categoryPlaceholder from '../clouds.jpg';
import { apiBaseUrl, toFullImgUrl } from '../utils';
import { useNavigateWithScrollPosition } from '../pages/hooks';

const CategoryCard = (props) => {
  const navigate = useNavigateWithScrollPosition();
  const { state } = useContext(StoreContext);
  const { isAdmin } = state;
  const { category, className } = props;
  const [productCount, setProductCount] = useState(null);
  const [showProductCount, setShowProductCount] = useState(false);

  const [featured, setFeatured] = useState(category.featured);

  const viewCategory = () => {
    navigate(`/search?category=${category.id}`);
  };

  useEffect(async () => {
    if (isAdmin) {
      const { data: count } = await axios.get(`${apiBaseUrl}/categories/${category.id}/productcount`);
      setProductCount(count);
    }
  }, []);

  const toggleFeatured = async (e) => {
    e.preventDefault();
    let newFeatured = !featured;
    setFeatured(newFeatured);
    try {
      await axios.patch(`${apiBaseUrl}/categories/${category.id}`, { featured: newFeatured });
    } catch {
      newFeatured = !newFeatured;
      setFeatured(newFeatured);
    }
  };

  return (
    <div className={`${className}`} style={{ willChange: 'transform' }}>
      <div
        className={`${isAdmin ? 'is-editable' : 'is-clickable'}`}
        onClick={() => !isAdmin && viewCategory()}
        onMouseEnter={() => setShowProductCount(true)}
        onMouseLeave={() => setShowProductCount(false)}
      >
        {isAdmin && <i onClick={viewCategory} className="fa fa-binoculars" /> }
        {isAdmin
        && (
        <i
          onClick={() => navigate(`/edit-category/${category.id}`)}
          className="fa fa-edit"
        />
        )}
        <figure className="image">
          <img style={{ height: 'var(--category-card-image)' }} className="category-card-image" src={category.image ? toFullImgUrl(category.image) : categoryPlaceholder} alt="category" />
          <div
            className="is-overlay"
            style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#00000099',
              width: '100%',
              height: '100%',
            }}
          />
          <div
            className="is-overlay is-size-6 has-text-weight-light"
            style={{
              color: '#ffffff',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: 'fit-content',
              maxHeight: 'fit-content',
            }}
          >
            <div className="truncate-2-lines">{category.name}</div>
            {showProductCount && productCount ? (<div className="is-size-7">{`(${productCount})`}</div>) : ''}
          </div>
          {
            isAdmin
          && (
          <i
            label="Featured"
            role="button"
            onKeyPress={null}
            tabIndex={0}
            onClick={toggleFeatured}
            className={`fa fa-star ${featured ? 'checked' : ''}`}
          />
          )
          }
        </figure>
      </div>
    </div>
  );
};

export default CategoryCard;
