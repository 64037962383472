/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import axios from 'axios';
import React, { useContext } from 'react';
import { useState } from 'reinspect';
import { StoreContext } from '../context/StoreContext';
import { useNavigateWithScrollPosition } from '../pages/hooks';
import { apiBaseUrl, toFullImgUrl } from '../utils';
import AddToCart from './AddToCart';
import ProductPriceRange from './ProductPriceRange';

const ProductCard = (props) => {
  const navigate = useNavigateWithScrollPosition();
  const { state } = useContext(StoreContext);
  const { isAdmin } = state;
  const { product, categories, className } = props;
  const [featured, setFeatured] = useState(product.featured);

  const viewProduct = () => navigate(`/view-product/${product.id}`);

  const toggleFeatured = async (e) => {
    e.preventDefault();
    let newFeatured = !featured;
    setFeatured(newFeatured);
    try {
      await axios.patch(`${apiBaseUrl}/products/${product.id}`, { featured: newFeatured });
    } catch {
      newFeatured = !newFeatured;
      setFeatured(newFeatured);
    }
  };

  return (
    <div className={className} style={{ willChange: 'transform' }}>
      <div className={`card ${isAdmin ? 'is-editable' : 'is-clickable'}`} onClick={() => !isAdmin && viewProduct()}>
        {isAdmin && <i onClick={viewProduct} className="fa fa-binoculars" /> }
        {isAdmin
        && <i onClick={() => navigate(`/edit-product/${product.id}`)} className="fa fa-edit" />}
        <div className="card-image">
          {
            // hack to keep the benefits of using an image ratio but changing the ratio itself
            // to 1.08
          }
          <figure className="image is-5by4" style={{ paddingTop: '92%' }}>
            <img
              src={toFullImgUrl(product.image)}
              alt={product.shortDesc}
            />
            {
              isAdmin
            && (
            <i
              label="Featured"
              role="button"
              onKeyPress={null}
              tabIndex={0}
              onClick={toggleFeatured}
              className={`fa fa-star ${featured ? 'checked' : ''}`}
            />
            )
            }
          </figure>
        </div>
        <div className={`card-content ${product.visible ? '' : 'has-background-white-ter has-text-grey-lighter'}`}>
          <div className="is-capitalized truncate-2-lines">
            <b>
              {product.name}
            </b>
          </div>
          <div>
            <ProductPriceRange product={product} categories={categories} />
          </div>
          <div className="is-clearfix pt-2">
            <AddToCart product={product} openProductIfHasVariant />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
