module.exports.canada =
{
  code: 'CA',
  name: 'Canada',
  divisionName: 'Province',
  postCodeName: 'Postal Code',
  supportedDivisions: [
    {
      code: 'AB',
      name: 'Alberta',
      taxItems: [
        {
          name: 'GST',
          percentage: '5'
        }
      ]
    },
    {
      code: 'BC',
      name: 'British Columbia',
      taxItems: [
        {
          name: 'GST',
          percentage: '5'
        }
      ]
    },
    {
      code: 'MB',
      name: 'Manitoba',
      taxItems: [
        {
          name: 'GST',
          percentage: '5'
        },
        {
          name: 'RST',
          percentage: '7'
        }
      ]
    },
    {
      code: 'NB',
      name: 'New Brunswick',
      taxItems: [
        {
          name: 'HST',
          percentage: '15'
        }
      ]
    },
    {
      code: 'NL',
      name: 'Newfoundland and Labrador',
      taxItems: [
        {
          name: 'HST',
          percentage: '15'
        }
      ]
    },
    {
      code: 'NT',
      name: 'Northwest Territories',
      taxItems: [
        {
          name: 'GST',
          percentage: '5'
        }
      ]
    },
    {
      code: 'NS',
      name: 'Nova Scotia',
      taxItems: [
        {
          name: 'HST',
          percentage: '15'
        }
      ]
    },
    {
      code: 'NU',
      name: 'Nunavut',
      taxItems: [
        {
          name: 'GST',
          percentage: '5'
        }
      ]
    },
    {
      code: 'ON',
      name: 'Ontario',
      taxItems: [
        {
          name: 'HST',
          percentage: '13'
        }
      ]
    },
    {
      code: 'PE',
      name: 'Prince Edward Island',
      taxItems: [
        {
          name: 'HST',
          percentage: '15'
        }
      ]
    },
    {
      code: 'QC',
      name: 'Quebec',
      taxItems: [
        {
          name: 'GST',
          percentage: '5'
        },
        {
          name: 'QST',
          percentage: '9.975'
        }
      ]
    },
    {
      code: 'SK',
      name: 'Saskatchewan',
      taxItems: [
        {
          name: 'GST',
          percentage: '5'
        },
        {
          name: 'PST',
          percentage: '6'
        }
      ]
    },
    {
      code: 'YT',
      name: 'Yukon',
      taxItems: [
        {
          name: 'GST',
          percentage: '5'
        }
      ]
    },

  ],
};
