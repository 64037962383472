/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';

const CustomModal = (props) => {
  const {
    showModal, hasNegativeButton = false,
    modalText, positiveButtonText = 'OK', negativeButtonText = 'Cancel', onPositiveButtonClick, onCancel,
  } = props;
  const modalElem = useRef(null);
  useEffect(() => {
    if (showModal && modalElem) {
      modalElem.current.scrollIntoView();
    }
  }, [showModal]);

  return (
    <div
      ref={modalElem}
      className={`modal ${showModal ? 'is-active' : ''}`}
      style={{
        position: 'fixed', bottom: '0', right: '0', height: '100vh', width: '100vw',
      }}
    >
      <div
        className="modal-background"
        onClick={onCancel}
        onKeyPress={onCancel}
        role="button"
        aria-label="modal background"
        tabIndex={0}
      />
      <div className="modal-content">
        <p className="has-text-primary is-size-5 pb-4">
          {modalText}
        </p>
        <p className="level is-mobile has-text-weight-bold">
          <span
            className="button has-text-primary-light has-background-primary level-item"
            onClick={onPositiveButtonClick}
            onKeyPress={onPositiveButtonClick}
            role="button"
            aria-label="yes"
            tabIndex={0}
          >
            {positiveButtonText}
          </span>
          {hasNegativeButton && (
            <span
              className="button has-text-primary-dark level-item mx-2"
              onClick={onCancel}
              onKeyPress={onCancel}
              role="button"
              aria-label="no"
              tabIndex={0}
            >
              {negativeButtonText}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default CustomModal;
