/* eslint-disable react/prop-types */
import React from 'react';
import { asPriceString, resolveProductPrice } from '@garaiecommerce/common';
import ProductPrice from './ProductPrice';

const ProductPriceRange = (props) => {
  const {
    product: productProp, categories,
  } = props;
  const product = { ...productProp };
  if (!product.variants) product.variants = [];
  if (!product.colorVariants) product.colorVariants = [];
  const prices = [];
  const salePrices = [];
  const evaluateForVariantCombination = (variant, colorVariant) => {
    const { price, salePrice } = resolveProductPrice(product, variant, colorVariant, categories);
    prices.push(price);
    if (salePrice) salePrices.push(salePrice);
  };
  product.variants.forEach((variant) => {
    if (product.colorVariants.length > 0) {
      product.colorVariants.forEach((colorVariant) => {
        evaluateForVariantCombination(variant, colorVariant);
      });
    } else {
      evaluateForVariantCombination(variant, null);
    }
  });
  if (product.variants.length === 0) {
    product.colorVariants.forEach((colorVariant) => {
      evaluateForVariantCombination(null, colorVariant);
    });
  }

  let minPrice; let maxPrice;
  if (salePrices.length > 0) {
    minPrice = asPriceString(Math.min(...salePrices));
    maxPrice = asPriceString(Math.max(...salePrices));
  } else {
    minPrice = asPriceString(Math.min(...prices));
    maxPrice = asPriceString(Math.max(...prices));
  }

  return prices.length > 0 && maxPrice > minPrice ? (
    <div className="truncate">
      {`$${minPrice} - $${maxPrice}`}
    </div>
  ) : (
    <ProductPrice product={product} categories={categories} />
  );
};

export default ProductPriceRange;
