/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Levenshtein from 'levenshtein';
import React, { useState } from 'react';
import { isFunction } from '../utils';

const Autocomplete = (props) => {
  const {
    className, label, name, placeholder, data, matchProp, onSelect, clearOnSelect,
    companionView,
  } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const [matches, setMatches] = useState([]);
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState(false);

  const dispatchSelection = (selection) => {
    setActiveIndex(0);
    if (clearOnSelect) {
      setQuery('');
      setSelected(false);
    } else {
      setQuery(selection[matchProp]);
      setSelected(true);
    }
    setMatches([]);
    if (isFunction(onSelect)) {
      onSelect(selection);
    }
  };

  const handleKeyPress = (event) => {
    switch (event.which) {
      case 13: // Enter key
        if (matches.length) {
          dispatchSelection(matches[activeIndex]);
        }
        break;
      case 38: // Up arrow
        setActiveIndex(activeIndex >= 1 ? activeIndex - 1 : 0);
        break;
      case 40: // Down arrow
        setActiveIndex(
          activeIndex < matches.length - 1
            ? activeIndex + 1
            : matches.length - 1,
        );
        break;
      default:
        break;
    }
  };

  const handleSelection = (event, selection) => {
    event.preventDefault();
    dispatchSelection(selection);
  };

  const updateQuery = (e) => {
    if (!selected) {
      const newQuery = e.target.value;
      // Used to sort matches based nearest result
      const compareDistance = (x, y) => new Levenshtein(
        x[matchProp].toUpperCase(),
        newQuery.toUpperCase(),
      ).distance
        - new Levenshtein(y[matchProp].toUpperCase(), newQuery.toUpperCase()).distance;

      setMatches(
        newQuery.length >= 2
          ? data.filter(
            (item) => item[matchProp].toUpperCase().indexOf(newQuery.toUpperCase()) >= 0,
          ).sort(compareDistance)
          : [],
      );
      setQuery(newQuery);
    } else if (e.nativeEvent.inputType === 'deleteContentBackward') {
      setMatches([]);
      setQuery('');
      setSelected(false);
    }
  };

  return (
    <>
      {label && <label className="label">{label}</label>}
      <div className={`dropdown ${matches.length > 0 ? 'is-active' : ''}`} style={{ width: '100%' }}>
        <div className="dropdown-trigger" style={{ width: '100%' }}>
          <input
            type="text"
            className={`input ${className}`}
            autoComplete="off"
            name={name}
            value={query}
            onChange={updateQuery}
            onKeyDown={handleKeyPress}
            placeholder={placeholder}
          />
        </div>
        <div className={`dropdown-menu  ${matches.length > 0 ? 'is-active' : ''}`} style={{ width: '100%' }}>
          {matches.length > 0 && (
          <div className="dropdown-content">
            {matches.map((match, index) => (
              <a
                className={`dropdown-item ${index === activeIndex ? 'is-active' : ''
                }`}
                href="/"
                key={match[matchProp]}
                onClick={(event) => handleSelection(event, match)}
              >
                {match[matchProp]}
                {isFunction(companionView) && companionView(match)}
              </a>
            ))}
          </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Autocomplete;
