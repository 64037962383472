import axios from 'axios';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { StoreContext } from '../context/StoreContext';
import { apiBaseUrl } from '../utils';

const Modal = () => {
  const { state, dispatch } = useContext(StoreContext);
  const { showBugReportModal } = state;
  const [details, setDetails] = useState('');
  const [submitError, setSubmitError] = useState();
  const hideBugReportModal = () => {
    dispatch({ type: 'HIDE_BUG_REPORT_MODAL' });
  };
  const modalElem = useRef(null);
  useEffect(() => {
    if (showBugReportModal && modalElem) {
      modalElem.current.scrollIntoView();
    }
  }, [showBugReportModal]);

  const onDetailsUpdate = (e) => {
    e.preventDefault();
    setDetails(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${apiBaseUrl}/bugreport`, { details });
    } catch (err) {
      setSubmitError(err.response.data.message || 'Something went wrong trying to send your bug report');
      return;
    }
    dispatch({ type: 'SHOW_SNACK_BAR', payload: { text: 'bug report submitted!' } });
    hideBugReportModal();
  };

  const onCancel = (e) => {
    e.preventDefault();
    hideBugReportModal();
  };

  return (
    <div
      ref={modalElem}
      className={`modal ${showBugReportModal ? 'is-active' : ''}`}
      style={{
        position: 'fixed', bottom: '0', right: '0', height: '100vh', width: '100vw',
      }}
    >
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Send a Bug Report</p>
        </header>
        <section className="modal-card-body">
          <textarea
            className="textarea is-small"
            placeholder="Enter bug report details here..."
            value={details}
            onChange={onDetailsUpdate}
            rows={8}
          />
          {submitError && <p className="pt-4 help is-danger">{submitError}</p>}
        </section>
        <footer className="modal-card-foot">
          <button type="button" className="button is-primary" onClick={onSubmit}>Submit</button>
          <button type="button" className="button" onClick={onCancel}>Cancel</button>
        </footer>
      </div>

    </div>
  );
};

export default Modal;
