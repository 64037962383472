import axios from 'axios';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { StoreContext } from '../context/StoreContext';
import { backendUrl } from '../utils';

const Logout = () => {
  const { dispatch } = useContext(StoreContext);
  const navigate = useNavigate();
  const handleLogOut = async () => {
    dispatch({ type: 'SET_LOGGED_OUT' });
    await axios.get(`${backendUrl}/logout`);
    navigate('/');
  };
  return (
    <button type="button" className="button is-outlined is-link" onClick={handleLogOut}>
      Log Out
    </button>

  );
};

export default Logout;
