import axios from 'axios';
import React, { createContext, useEffect, useMemo } from 'react';
import { useReducer } from 'reinspect';
import { apiBaseUrl, remove } from '../utils';

export const CART_LOCAL_STORAGE_KEY = 'cart';
export const PENDING_PAYMENT_LOCAL_STORAGE_KEY = 'pendingPayment';

export const PENDING_PAYMENT_MODAL_SELECTION_RESTORE = 'restore';
export const PENDING_PAYMENT_MODAL_SELECTION_DISCARD = 'discard';

// Create Context Object
export const StoreContext = createContext();

const initialState = {
  user: null,
  userDetails: {},
  error: null,
  showModal: false,
  modalText: '',
  showBugReportModal: false,
  showSnackBar: false,
  snackBarText: '',
  isLoggedIn: false,
  checkedInitialLogin: false,
  isAdmin: false,
  cart: [],
  showPendingPaymentModal: false,
  pendingPayment: null,
  paymentModalSelection: null,
  showHoveringCartIcon: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOGGED_IN': {
      const { isAdmin, id: user, ...userDetails } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        checkedInitialLogin: true,
        isAdmin,
        user,
        userDetails,
      };
    }
    case 'SET_CHECKED_INITIAL_LOGIN':
      return { ...state, checkedInitialLogin: true };
    case 'UPDATE_USER_DETAILS':
      return { ...state, userDetails: { ...action.payload } };
    case 'SET_LOGGED_OUT':
      return {
        ...state,
        isLoggedIn: false,
        isAdmin: false,
        user: null,
        userDetails: {},
      };
    case 'SHOW_MODAL':
      return { ...state, showModal: true, modalText: action.payload.text };
    case 'HIDE_MODAL':
      return { ...state, showModal: false, modalText: '' };
    case 'SHOW_BUG_REPORT_MODAL':
      return { ...state, showBugReportModal: true };
    case 'HIDE_BUG_REPORT_MODAL':
      return { ...state, showBugReportModal: false };
    case 'SHOW_SNACK_BAR':
      return { ...state, showSnackBar: true, snackBarText: action.payload.text };
    case 'HIDE_SNACK_BAR':
      return { ...state, showSnackBar: false, snackBarText: '' };
    case 'RESTORE_CART':
      return { ...state, cart: [...action.payload.cart] };
    case 'ADD_TO_CART':
    {
      let { cart } = state;
      const { id, selectedVariant, selectedColorVariant } = action.payload;
      // Add to existing cart item if product id matches and both of the
      // following conditions are true:
      // (1) Variant exists in both the new item and the existing cart item and
      // the variant matches, OR variant does not exist in either item
      // (2) Color variant exists in both the new item and the existing cart
      // item and the variant matches, OR variant does not exist in either item
      const cartItem = cart.find((item) => item.id === id
        && ((!item.selectedVariant && !selectedVariant)
        || ((item.selectedVariant && selectedVariant)
         && (item.selectedVariant.name === selectedVariant.name))
        )
        && ((!item.selectedColorVariant && !selectedColorVariant)
        || ((item.selectedColorVariant && selectedColorVariant)
          && (item.selectedColorVariant.colorId === selectedColorVariant.colorId))
        ));
      if (cartItem) {
        cartItem.count += 1;
      } else {
        cart = [...cart, { ...action.payload, count: 1 }];
      }
      return { ...state, cart };
    }
    case 'UPDATE_CART_ITEM':
    {
      const { cart } = state;
      const cartItem = cart.find((item) => item.cartItemId === action.payload.cartItemId);
      if (cartItem) {
        const newCartItem = { ...cartItem, count: action.payload.count };
        const index = cart.indexOf(cartItem);
        cart[index] = newCartItem;
      } else {
        // eslint-disable-next-line no-console
        console.warn('got update request for a non existent cart item');
      }
      return { ...state, cart: [...cart] };
    }
    case 'REMOVE_FROM_CART':
    {
      let { cart } = state;
      cart = remove(cart, cart.findIndex((item) => item.cartItemId === action.payload.cartItemId));
      return { ...state, cart };
    }
    case 'CLEAR_CART':
      return { ...state, cart: [] };
    case 'SHOW_PENDING_PAYMENT_MODAL':
      return {
        ...state,
        showPendingPaymentModal: true,
        pendingPayment: action.payload,
        paymentModalSelection: null,
      };
    case 'DISMISS_PENDING_PAYMENT_MODAL':
      return {
        ...state,
        showPendingPaymentModal: false,
        pendingPayment: null,
        paymentModalSelection: null,
      };
    case 'MAKE_PENDING_PAYMENT_MODAL_SELECTION':
      return {
        ...state,
        paymentModalSelection: action.payload.selection,
      };
    case 'ERROR':
      return { ...state, error: action.payload };
    case 'SHOW_HOVERING_CART_ICON':
      return { ...state, showHoveringCartIcon: true };
    case 'HIDE_HOVERING_CART_ICON':
      return { ...state, showHoveringCartIcon: false };
    default:
      throw new Error(`unknown action type: ${action.type}`);
  }
};

// Create a provider for components to consume and subscribe to changes
export const StoreContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState, (s) => s, 'storeState');
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  const { cart, paymentModalSelection, pendingPayment } = state;

  // Some useEffect hooks are added here because they need to be done at startup
  // before the store state is created and using them in App.jsx may not work

  // Restore cart from local storage on fresh launch
  useEffect(() => {
    const cartFromStorage = window.localStorage.getItem(CART_LOCAL_STORAGE_KEY);
    if (cartFromStorage) {
      dispatch({ type: 'RESTORE_CART', payload: { cart: JSON.parse(cartFromStorage) } });
    }
  }, []);

  // Check any pending payments
  // NOTE: current assumption is there is virtually no chance of both cart and
  // pending payment to remain persisted, the site should prevent a new cart
  // from being created before the user resolving a pending payment
  useEffect(async () => {
    const pendingPaymentFromStorage = window.localStorage.getItem(
      PENDING_PAYMENT_LOCAL_STORAGE_KEY,
    );
    if (pendingPaymentFromStorage) {
      const pendingPaymentData = JSON.parse(pendingPaymentFromStorage);
      try {
        const { data: response } = await axios.get(`${apiBaseUrl}/paymentrequests/${pendingPaymentData.paymentRequestId}`);
        if (response) {
          if (response.status === 'COMPLETED') {
            // all good, we can clear it now
            window.localStorage.removeItem(PENDING_PAYMENT_LOCAL_STORAGE_KEY);
          } else if (response.status === 'EXPIRED' || response.status === 'PENDING') {
            dispatch({ type: 'SHOW_PENDING_PAYMENT_MODAL', payload: { ...pendingPaymentData, status: response.status } });
            return;
          } else {
            // Treat it like an invalid payment request
            window.localStorage.removeItem(PENDING_PAYMENT_LOCAL_STORAGE_KEY);
          }
        }
      } catch (err) {
        if (err.response && err.response.status && err.response.status === 404) {
          // Data for this payment request no longer exists on the server
          // We will just delete it as it's too old
          window.localStorage.removeItem(PENDING_PAYMENT_LOCAL_STORAGE_KEY);
        }
      }
    }
  }, []);

  // Persist cart in local storage on every cart update
  useEffect(() => {
    window.localStorage.setItem(CART_LOCAL_STORAGE_KEY, JSON.stringify(cart));
  }, [cart]);

  // Handle payment modal selection
  useEffect(async () => {
    if (paymentModalSelection) {
      if (pendingPayment.status === 'PENDING') {
        // delete on server as we no longer need to query this payment request
        // this is important to do first in order to restore the stock
        try {
          await axios.delete(`${apiBaseUrl}/paymentrequests/${pendingPayment.paymentRequestId}`);
        } catch (err) {
          // ignore errors here
        }
      }
      if (paymentModalSelection === PENDING_PAYMENT_MODAL_SELECTION_RESTORE) {
        dispatch({ type: 'RESTORE_CART', payload: { cart: [...(pendingPayment.cart)] } });
      }
      // user has made a selection and so we can safely remove it from storage now
      window.localStorage.removeItem(PENDING_PAYMENT_LOCAL_STORAGE_KEY);
      // finally dismiss the modal
      dispatch({ type: 'DISMISS_PENDING_PAYMENT_MODAL' });
    }
  }, [paymentModalSelection]);

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
};
