import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { StateInspector } from 'reinspect';
import { StoreContextProvider } from './context/StoreContext';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './mybulma.css';

ReactDOM.render(
  <React.StrictMode>
    <StateInspector name="StoreContextProvider">
      <StoreContextProvider>
        <App />
      </StoreContextProvider>
    </StateInspector>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
