import React, { useContext, useEffect, useRef } from 'react';
import { StoreContext } from '../context/StoreContext';

// TODO reuse CustomModal
const Modal = () => {
  const { state, dispatch } = useContext(StoreContext);
  const { showModal } = state;
  const { modalText } = state;
  const hideModal = () => {
    dispatch({ type: 'HIDE_MODAL' });
  };
  const modalElem = useRef(null);
  useEffect(() => {
    if (showModal && modalElem) {
      modalElem.current.scrollIntoView();
    }
  }, [showModal]);

  return (
    <div
      ref={modalElem}
      className={`modal ${showModal ? 'is-active' : ''}`}
      style={{
        position: 'fixed', bottom: '0', right: '0', height: '100vh', width: '100vw',
      }}
    >
      <div
        className="modal-background"
        onClick={hideModal}
        onKeyPress={hideModal}
        role="button"
        aria-label="modal background"
        tabIndex={0}
      />
      <div className="modal-content">
        <p className="has-text-primary is-size-5 pb-4">
          {modalText}
        </p>
        <p className="level is-mobile has-text-weight-bold">
          <span
            className="button has-text-primary-dark level-item"
            onClick={hideModal}
            onKeyPress={hideModal}
            role="button"
            aria-label="yes"
            tabIndex={0}
          >
            OK
          </span>
        </p>
      </div>
    </div>
  );
};

export default Modal;
