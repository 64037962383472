import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { StoreContext } from '../context/StoreContext';
import { saveProductsScrollPosition } from '../utils';

const usePageLoad = (callback, navigateToRoot = true, observables = []) => {
  const { dispatch } = useContext(StoreContext);
  const navigate = useNavigate();
  useEffect(async () => {
    try {
      if (callback) await callback();
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err.message });
      if (navigateToRoot) navigate('/');
    }
  }, observables);
};

const useEnforceAdmin = () => {
  const { state } = useContext(StoreContext);
  const navigate = useNavigate();
  const { isAdmin, checkedInitialLogin } = state;
  // navigate away as soon as we are done checking login
  useEffect(() => {
    if (checkedInitialLogin && !isAdmin) {
      navigate('/');
    }
  }, [checkedInitialLogin]);
};

const useEnforceLoggedIn = () => {
  const { state } = useContext(StoreContext);
  const navigate = useNavigate();
  const { isLoggedIn, checkedInitialLogin } = state;
  // navigate away as soon as we are done checking login
  useEffect(() => {
    if (checkedInitialLogin && !isLoggedIn) {
      navigate('/');
    }
  }, [checkedInitialLogin]);
};

const useNavigateWithScrollPosition = () => {
  const navigate = useNavigate();
  return (path) => {
    saveProductsScrollPosition();
    navigate(path);
  };
};

export {
  usePageLoad, useNavigateWithScrollPosition, useEnforceAdmin, useEnforceLoggedIn,
};
