/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/button-has-type */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { PENDING_PAYMENT_MODAL_SELECTION_DISCARD, PENDING_PAYMENT_MODAL_SELECTION_RESTORE, StoreContext } from '../context/StoreContext';

const PendingPaymentModal = () => {
  const { state, dispatch } = useContext(StoreContext);
  const { showPendingPaymentModal } = state;
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [restoreButtonLoading, setRestoreButtonLoading] = useState(false);
  const [discardButtonLoading, setDiscardButtonLoading] = useState(false);
  const restoreCart = () => {
    setButtonsDisabled(true);
    setRestoreButtonLoading(true);
    dispatch({ type: 'MAKE_PENDING_PAYMENT_MODAL_SELECTION', payload: { selection: PENDING_PAYMENT_MODAL_SELECTION_RESTORE } });
  };
  const discardCart = () => {
    setButtonsDisabled(true);
    setDiscardButtonLoading(true);
    dispatch({ type: 'MAKE_PENDING_PAYMENT_MODAL_SELECTION', payload: { selection: PENDING_PAYMENT_MODAL_SELECTION_DISCARD } });
  };
  const modalElem = useRef(null);
  useEffect(() => {
    if (showPendingPaymentModal && modalElem) {
      modalElem.current.scrollIntoView();
    }
  }, [showPendingPaymentModal]);

  return (
    <div
      ref={modalElem}
      className={`modal ${showPendingPaymentModal ? 'is-active' : ''}`}
      style={{
        position: 'fixed', bottom: '0', right: '0', height: '100vh', width: '100vw',
      }}
    >
      <div
        className="modal-background"
        role="button"
        aria-label="modal background"
        tabIndex={0}
      />
      <div className="modal-content p-4">
        <p className="has-text-primary is-size-5 pb-4">
          There was an error completing your previous order. Would you like to recover it?
        </p>
        <p className="level is-mobile has-text-weight-bold">
          <button
            disabled={buttonsDisabled}
            className={`mr-4 button has-text-primary-light has-background-primary level-item ${restoreButtonLoading ? 'is-loading' : ''}`}
            onClick={restoreCart}
            onKeyPress={restoreCart}
            role="button"
            aria-label="yes"
            tabIndex={0}
          >
            Yes - Recover
          </button>
          <button
            disabled={buttonsDisabled}
            className={`button has-text-primary-dark level-item ${discardButtonLoading ? 'is-loading' : ''}`}
            onClick={discardCart}
            onKeyPress={discardCart}
            role="button"
            aria-label="no"
            tabIndex={0}
          >
            No - Clear
          </button>
        </p>
      </div>
    </div>
  );
};

export default PendingPaymentModal;
