/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import {
  asPrice, getShortOrderName, resolveOrderItemName, resolveOrderSubtotal,
} from '@garaiecommerce/common';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { v4 } from 'uuid';
import { StoreContext } from '../context/StoreContext';
import { apiBaseUrl } from '../utils';
import CustomModal from './CustomModal';

const Order = (props) => {
  const {
    order, selected, onOrderClick, onReload, filteredForUser,
  } = props;
  const { dispatch } = useContext(StoreContext);
  const shortOrderName = getShortOrderName(order);

  const [shipped, setShipped] = useState(order.shipped);
  const [delivered, setDelivered] = useState(order.delivered);
  const [tracking, setTracking] = useState(order.tracking);
  const [refundIdempotencyKey] = useState(() => v4());
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundRequested, setRefundRequested] = useState(false);

  const handleToggleShipped = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!order.paid) return;
    if (filteredForUser) return;
    const newShipped = !shipped;
    try {
      await axios.patch(`${apiBaseUrl}/orders/${order.id}`, { shipped: newShipped });
      setShipped(newShipped);
      setTimeout(onReload, 400);
    } catch {
      dispatch({ type: 'SHOW_SNACK_BAR', payload: { text: 'There was an error updating shipped state' } });
    }
  };

  const handleToggleDelivered = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!order.paid) return;
    if (filteredForUser) return;
    const newDelivered = !delivered;
    try {
      await axios.patch(`${apiBaseUrl}/orders/${order.id}`, { delivered: newDelivered });
      setDelivered(newDelivered);
      setTimeout(onReload, 400);
    } catch {
      dispatch({ type: 'SHOW_SNACK_BAR', payload: { text: 'There was an error updating delivered state' } });
    }
  };

  const handleTrackingUpdate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!order.paid) return;
    if (filteredForUser) return;
    setTracking(e.target.value);
  };

  const handleTrackingEntered = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await axios.patch(`${apiBaseUrl}/orders/${order.id}`, { tracking });
      setTimeout(onReload, 400);
    } catch {
      dispatch({ type: 'SHOW_SNACK_BAR', payload: { text: 'There was an error updating tracking #' } });
    }
  };

  const subtotal = resolveOrderSubtotal(order);

  const onClickRefund = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowRefundModal(true);
  };

  const onClickRefundProceed = () => {
    setShowRefundModal(false);
    setRefundRequested(true);
    axios.patch(`${apiBaseUrl}/orders/${order.id}/refund/${refundIdempotencyKey}`);
  };

  return (
    <div className="order my-5 is-size-6">
      <CustomModal
        showModal={showRefundModal}
        modalText={`Are you sure you want to issue a refund for order # ${order.id}?`}
        hasNegativeButton
        positiveButtonText="Yes"
        onPositiveButtonClick={onClickRefundProceed}
        onCancel={() => setShowRefundModal(false)}
      />
      <div
        className={`columns is-vcentered ${order.paid ? 'has-background-white-ter' : 'has-background-grey-lighter'}`}
        onClick={() => onOrderClick(order)}
      >
        <div className="column is-size-7">
          <div className="">
            <span className="has-text-grey-light">Order #</span>
            {filteredForUser ? <br /> : ' '}
            {order.id}
          </div>
          <div className="pt-1">
            {`${new Date(order.creationTime).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}`}
          </div>
          {!filteredForUser && (
            <div className="pt-1">
              {`${order.userName || order.billingDetails.email || ''}`}
            </div>
          )}
        </div>
        <div className="column">
          {shortOrderName}
        </div>
        <div className="column">
          {!order.refundStatus && !refundRequested
            && (order.paid
              ? <div className="has-text-success">Paid</div>
              : <div className="has-text-danger">Pending</div>)}
          {order.refundStatus && (
          <div className="has-text-warning-dark">
            {order.refundStatus === 'COMPLETED'
              ? (<span className="has-text-weight-semibold">REFUNDED</span>)
              : (
                <div className="is-size-7 has-text-weight-semibold">
                  REFUND
                  {' '}
                  {order.refundStatus}
                </div>
              )}
          </div>
          )}
          {!filteredForUser && !refundRequested && (!order.refundStatus || (order.refundStatus !== 'COMPLETED' && order.refundStatus !== 'PENDING')) && (
            <div>
              <button onClick={onClickRefund} type="button" className="button is-small is-warning m-1">REFUND</button>
            </div>
          )}
          {refundRequested && <div className="is-size-7">Refund Requested</div>}
        </div>
        <div className="column">
          <label className="m-1 checkbox button is-light is-warning" disabled={!order.paid || filteredForUser} onClick={handleToggleShipped}>
            {order.shippingDetails === 'pickup' ? 'Ready for pick-up' : 'Shipped:'}
            {' '}
            <input disabled={!order.paid} type="checkbox" checked={shipped} onChange={(e) => e.preventDefault()} />
          </label>
          <label className="m-1 checkbox ml-2 button is-success is-light" disabled={!order.paid || filteredForUser} onClick={handleToggleDelivered}>
            {order.shippingDetails === 'pickup' ? 'Picked-up' : 'Delivered:'}
            {' '}
            <input disabled={!order.paid} type="checkbox" checked={delivered} onChange={(e) => e.preventDefault()} />
          </label>
        </div>
        <div className="column">
          {filteredForUser
            ? (
              <div className="is-size-7">
                <div className="has-text-grey-light">
                  Tracking:
                </div>
                <div>
                  {tracking}
                </div>

              </div>
            ) : (
              <div className="control" onClick={(e) => e.stopPropagation()}>
                <span className="columns is-mobile">
                  <input className="column is-offset-1 is-10 input is-small" type="text" placeholder="Enter Tracking Info" value={tracking} onChange={handleTrackingUpdate} />
                  <i className="column is-clickable is-small fa fa-check-circle" onClick={handleTrackingEntered} />
                </span>
              </div>
            )}
        </div>
        <div className="column">
          <span className="has-text-weight-semibold">{`Total Paid: $${asPrice(order.totalCost)}`}</span>
        </div>
        <div className="column is-1"><i className={`has-text-dark fa fa-chevron-${selected ? 'up' : 'down'}`} /></div>
      </div>
      <div className={`has-background-white-bis columns is-size-7 box pt-0 collapsible ${selected ? 'collapsible-active' : ''}`} style={{ textAlign: 'left' }}>
        <div className="column is-3">
          <ul style={{ listStyle: 'square', listStylePosition: 'inside' }}>
            <div className="is-size-6">Order Items:</div>
            {order.items.map((item) => (
              <li className="is-size-6" key={item.id}>
                <a target="_blank" href={`/view-product/${item.id}`} rel="noreferrer">{resolveOrderItemName(item)}</a>
                {' '}
                x
                {item.count}
                {' '}
                @
                {' '}
                $
                {asPrice(item.price.salePrice || item.price.price)}
              </li>
            ))}
          </ul>
        </div>
        <div className="column">
          <div className="is-size-6 mb-2">Shipping Details:</div>
          {
            // eslint-disable-next-line no-nested-ternary
            order.shippingDetails
              ? (order.shippingDetails === 'pickup' ? 'pick-up'
                : (
                  <>
                    <div>
                      {order.shippingDetails.firstName}
                      {' '}
                      {order.shippingDetails.lastName}
                    </div>
                    <div>
                      {order.shippingDetails.addrLine1}
                    </div>
                    <div>
                      {order.shippingDetails.addrLine2}
                    </div>
                    <div>
                      {order.shippingDetails.city}
                      ,
                      {' '}
                      {order.shippingDetails.countryDivision}
                      ,
                      {' '}
                      {order.shippingDetails.postCode.toUpperCase()}
                    </div>
                    <div>
                      Country:
                      {' '}
                      {order.shippingDetails.country}
                    </div>
                    <div>
                      {order.shippingDetails.email}
                    </div>
                    <div>
                      {order.shippingDetails.phone}
                    </div>
                  </>
                )
              ) : 'Pending'
          }
        </div>
        <div className="column">
          <div className="is-size-6 mb-2">Billing Details:</div>
          <div>
            {order.billingDetails.firstName}
            {' '}
            {order.billingDetails.lastName}
          </div>
          <div>
            {order.billingDetails.email}
          </div>
        </div>
        <div className="column">
          <div className="is-size-6">Taxes:</div>
          <ul style={{ listStyle: 'square', listStylePosition: 'inside' }}>
            {
            order.taxItems ? (
              order.taxItems.map((taxItem) => (
                <li key={taxItem.name}>
                  {taxItem.name}
                  :
                  {' '}
                  {taxItem.percentage}
                  %
                  {' = $'}
                  {asPrice((parseFloat(taxItem.percentage) / 100) * (subtotal))}
                </li>
              ))
            ) : 'None'
          }
          </ul>
          <div className="is-size-6 mt-2 mb-1">Shipping:</div>
          <span>{`${order.shippingCost ? `$${asPrice(order.shippingCost)}` : 'Free'}`}</span>
        </div>
        <div className="column">
          <div className="is-size-6 mb-2" style={{ whiteSpace: 'pre-wrap' }}>Instructions:</div>
          {(
          order.instructions
            ? (
              <textarea style={{ minHeight: '6em', wordBreak: 'break-all' }} className="textarea" disabled value={order.instructions} />
            )
            : 'None '
          )}
        </div>
      </div>
    </div>
  );
};

export default Order;
