/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useContext, useEffect, lazy, Suspense, useRef,
} from 'react';
import { useState } from 'reinspect';
import {
  Routes, Route, BrowserRouter as Router, Link,
} from 'react-router-dom';
import { Navigate } from 'react-router';

import axios from 'axios';
import { StoreContext } from './context/StoreContext';

import './App.css';
import logo from './logo250.png';
import {
  BugReportModal,
  Logout, Modal, PendingPaymentModal, ScrollToTop, SearchBox, SnackBar,
} from './components';
import { backendUrl, useOnScreen } from './utils';

const ProductPage = lazy(() => import('./pages/ProductPage'));
const Cart = lazy(() => import('./pages/Cart'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const AllCategories = lazy(() => import('./pages/AllCategories'));
const CategoryForm = lazy(() => import('./pages/CategoryForm'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Login = lazy(() => import('./pages/Login'));
const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess'));
const ProductForm = lazy(() => import('./pages/ProductForm'));
const Products = lazy(() => import('./pages/Products'));
const Signup = lazy(() => import('./pages/Signup'));
const Orders = lazy(() => import('./pages/Orders'));
const Search = lazy(() => import('./pages/Search'));
const MyAccount = lazy(() => import('./pages/MyAccount'));
const Faq = lazy(() => import('./pages/Faq'));

const App = () => {
  const { state, dispatch } = useContext(StoreContext);
  const {
    isLoggedIn, isAdmin, cart,
    showHoveringCartIcon, user, userDetails,
  } = state;
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  // eslint-disable-next-line no-restricted-syntax

  const numCartItems = cart.reduce((sum, item) => sum + item.count, 0);
  const handleClickNavbarItem = ((event) => {
    event.target.blur();
    setShowBurgerMenu(false);
  });

  if (process.env.NODE_ENV === 'development') {
    useEffect(() => {
      // Need this for cross-site access control header
      // as development UI server is different from backend.
      // This is only needed because we get a session cookie from
      // Passport local strategy. This won't be required if we
      // used JWT or possibly OAuth tokens
      axios.defaults.withCredentials = true;
    }, []);
  }

  const navbarEndRef = useRef();
  const isNavbarEndVisible = useOnScreen(navbarEndRef);

  useEffect(() => {
    axios.get(`${backendUrl}/users/me`)
      .then((response) => {
        dispatch({ type: 'SET_LOGGED_IN', payload: response.data });
      })
      .catch(() => {
        dispatch({ type: 'SET_CHECKED_INITIAL_LOGIN' });
      });
  }, []);

  const cartIcon = (style) => (
    <Link
      to="/cart"
      className="button is-light is-rounded is-responsive p-2 is-normal is-pulled-right"
      style={style}
      onClick={handleClickNavbarItem}
    >
      <i className="fa fa-shopping-cart is-size-5" aria-hidden="true" />
      <span className="is-size-7 pt-2 pl-2 has-text-link">{numCartItems > 0 ? numCartItems : ''}</span>
    </Link>
  );

  return (
    <Router>
      <ScrollToTop />
      <PendingPaymentModal />
      <Modal />
      <BugReportModal />
      <div className="App">
        <nav className="navbar" role="navigation" aria-label="main navigation" style={{ top: '0px', height: 'auto', willChange: 'transform' }}>
          <div className="navbar-brand">
            <div className="navbar-item">
              <Link to="/">
                <img className="app-logo" alt="lunari" src={logo} />
              </Link>
            </div>

            <div
              role="button"
              onKeyPress={null}
              tabIndex={0}
              onClick={(e) => {
                e.preventDefault();
                setShowBurgerMenu(!showBurgerMenu);
              }}
              className={`navbar-burger ${showBurgerMenu ? 'is-active' : ''}`}
              aria-label="menu"
              aria-expanded="false"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </div>
          </div>

          <div className={`navbar-menu ${showBurgerMenu ? 'is-active' : ''}`}>
            <div className="navbar-start">
              <Link to="/products" className="navbar-item" onClick={handleClickNavbarItem}>
                Products
              </Link>

              <Link to="/about" className="navbar-item" onClick={handleClickNavbarItem}>
                About Us
              </Link>
              {
                isAdmin
                 && (
                 <Link
                   to="/add-product"
                   className="navbar-item"
                   onClick={handleClickNavbarItem}
                 >
                   Add Product
                 </Link>
                 )
              }
              {
                isAdmin
                && (
                <Link to="/add-category" className="navbar-item" onClick={handleClickNavbarItem}>
                  Add Category
                </Link>
                )
              }
              {
                isAdmin
                && (
                <Link to="/orders" className="navbar-item" onClick={handleClickNavbarItem}>
                  Orders
                </Link>
                )
              }
              <div className="navbar-item field">
                <SearchBox />
              </div>
            </div>

            <div
              className="navbar-item"
              role="button"
              style={{ cursor: 'pointer' }}
              onClick={() => dispatch({ type: 'SHOW_BUG_REPORT_MODAL' })}
            >
              <i className="fa fa-bug mr-2 is-size-5" />
              <span className="is-size-7 has-text-link">Report Bug</span>
            </div>

            <div className="navbar-end" ref={navbarEndRef}>
              <div className="navbar-item">
                {
                  isLoggedIn && (
                  <Link to="/myaccount" onClick={handleClickNavbarItem} className="has-text-link level is-mobile is-centered mx-4">
                    <i className="fa fa-user" />
                    <span className="mx-2">{userDetails.firstName || user}</span>
                  </Link>
                  )
                }
                <div className="buttons">
                  {
                    !isLoggedIn ? (
                      <>
                        <Link to="/signup" className="button is-primary" onClick={handleClickNavbarItem}>
                          <strong>Sign up</strong>
                        </Link>
                        <Link to="/login" className="button is-light" onClick={handleClickNavbarItem}>
                          Log in
                        </Link>

                      </>
                    ) : <Logout />
                  }
                  {(!showHoveringCartIcon || isNavbarEndVisible) && numCartItems > 0 && cartIcon()}
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* <Products /> */}
        <Suspense fallback={<span className="app-load-spinner" />}>
          <Routes>
            <Route path="/" element={<Navigate to="/products" />} />
            <Route path="/products" element={<Products />} />
            <Route path="/categories" element={<AllCategories />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/search" element={<Search />} />
            <Route path="/paymentsuccess/:id" element={<PaymentSuccess />} />
            <Route path="/add-product" element={<ProductForm />} />
            <Route path="/edit-product/:id" element={<ProductForm />} />
            <Route path="/add-category" element={<CategoryForm />} />
            <Route path="/edit-category/:id" element={<CategoryForm />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/myaccount" element={<MyAccount />} />
            <Route path="/view-product/:id" element={<ProductPage />} />
            <Route path="*" element={<Navigate to="/products" />} />
          </Routes>
        </Suspense>
        {showHoveringCartIcon && !isNavbarEndVisible && numCartItems > 0
            && cartIcon({
              position: 'sticky', bottom: '2rem', right: '0.5rem', zIndex: 100,
            })}
        <footer className="footer mt-6">
          <div className="content has-text-centered is-size-7">© 2022 Lunari Home</div>
          <nav className="breadcrumb is-centered has-dot-separator" aria-label="breadcrumbs">
            <ul>
              <li><a href="/faq">FAQ</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="http://www.etsy.com/ca/shop/LunariHome">Etsy</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="http://instagram.com/lunarihome">Instagram</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="http://facebook.com/lunarihome">Facebook</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.amazon.ca/dp/B09JRJ1L9D/ref=cm_sw_r_apan_glt_i_C1BE5KC3Z5G6V08NP42X">Lunari Official Coloring Book</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="http://twitter.com/lunarihome">Twitter</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://youtube.com/channel/UCzWcwDcPuwI3NtFLDIeRz1Q">YouTube</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="http://pinterest.com/lunarihome">Pinterest</a></li>
            </ul>
          </nav>
          <div className="content is-pulled-right is-size-7">
            {process.env.REACT_APP_RELEASE_BRANCH}
            {' v'}
            {process.env.REACT_APP_VERSION}
          </div>

        </footer>
      </div>
      <SnackBar />
    </Router>
  );
};

export default App;
