/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef } from 'react';
import { useState } from 'reinspect';
import { isFunction, isNotDesktop } from '../../utils';
import Arrow from '../Arrow';

import './styles.css';

const CURRENT_IMG_HEIGHT = '33vw';

const ImageGallery = (props) => {
  const {
    images, className, currentIndex: currentIndexFromProps, onCurrentImageUpdate,
  } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState(currentIndexFromProps || 0);
  const [showModal, setShowModal] = useState(false);
  const [hideImageTiles, setHideImageTiles] = useState(isNotDesktop());
  const handleResize = () => setHideImageTiles(isNotDesktop());
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const imageRefs = useRef([]);
  const [imagesPendingLoad, setImagesPendingLoad] = useState(new Set(images.keys()));
  const onImageLoad = (index) => {
    imagesPendingLoad.delete(index);
    setImagesPendingLoad(new Set([...imagesPendingLoad]));
  };
  const imageTiles = [];
  const updateCurrentImageIndex = (index, skipNotify = false) => {
    if (!skipNotify && isFunction(onCurrentImageUpdate)) {
      onCurrentImageUpdate(index);
    }
    if (hideImageTiles && imageRefs.current.length > index) {
      imageRefs.current[index].scrollIntoView({ block: 'nearest', inline: 'start' });
    }
    setCurrentImageIndex(index);
  };
  useEffect(() => {
    if (currentIndexFromProps) updateCurrentImageIndex(currentIndexFromProps, true);
  }, [currentIndexFromProps]);
  if (images.length > 0) {
    images.forEach((image, index) => {
      imageTiles.push(
        <figure
          className="image pb-1"
          key={index}
        >
          <img
            id={`product-img-tile-${index}`}
            alt=""
            src={image}
            style={{ height: '8vw' }}
            className={`${index === currentImageIndex ? '' : 'is-clickable inactive-image'}`}
            onKeyUp={index === currentImageIndex ? null : () => {
              updateCurrentImageIndex(index);
            }}
            onClick={index === currentImageIndex ? null : () => {
              updateCurrentImageIndex(index);
            }}
          />
        </figure>,
      );
    });
  }
  const handleKeyEvent = (e) => {
    if (e.key === 'Escape') {
      setShowModal(false);
    } else if (e.key === 'ArrowLeft') {
      if (currentImageIndex === 0) return;
      updateCurrentImageIndex(currentImageIndex - 1);
    } else if (e.key === 'ArrowRight') {
      if (currentImageIndex === images.length - 1) return;
      updateCurrentImageIndex(currentImageIndex + 1);
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyEvent, false);
    return () => {
      document.removeEventListener('keydown', handleKeyEvent, false);
    };
  });

  const handleScrollEvent = (e) => {
    if (e.target.scrollLeft % e.target.offsetWidth === 0) {
      const scrollIndex = e.target.scrollLeft / e.target.offsetWidth;
      updateCurrentImageIndex(scrollIndex);
    }
  };

  const arrowStyleBase = {
    position: 'fixed',
    zIndex: '10',
    top: '50%',
  };
  return (
    <>
      <div className={`modal ${showModal ? 'is-active' : ''}`}>
        <div
          className="modal-background"
          onClick={() => setShowModal(false)}
          onKeyUp={() => setShowModal(false)}
          role="region"
        />
        <div className="modal-content">
          <Arrow
            direction="left"
            style={{
              ...arrowStyleBase,
              left: '1em',
              visibility: `${currentImageIndex > 0 ? 'visible' : 'hidden'}`,
            }}
            onClick={() => updateCurrentImageIndex(currentImageIndex - 1)}
          />
          <p className="image p-6">
            <img src={images[currentImageIndex]} alt="" />
          </p>
          <Arrow
            direction="right"
            style={{
              ...arrowStyleBase,
              right: '1em',
              visibility: `${currentImageIndex < images.length - 1 ? 'visible' : 'hidden'}`,
            }}
            onClick={() => updateCurrentImageIndex(currentImageIndex + 1)}
          />
        </div>
        <button
          type="button"
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setShowModal(false)}
        />
      </div>
      {
        hideImageTiles
          ? (
            <div className={`${className} is-unselectable`}>
              <p
                className="image"
              >
                <Arrow
                  direction="left"
                  style={{
                    ...arrowStyleBase,
                    position: 'absolute',
                    left: '0.2em',
                    visibility: `${currentImageIndex > 0 ? 'visible' : 'hidden'}`,
                  }}
                  onClick={() => updateCurrentImageIndex(currentImageIndex - 1)}
                />
                <div
                  className={`gallery-scroll-container ${imagesPendingLoad.size ? '' : 'gallery-scroll-container-snappy'}`}
                  onScroll={handleScrollEvent}
                >
                  {images.map((image, index) => (
                    <img
                      key={image}
                      // eslint-disable-next-line no-return-assign
                      ref={(el) => imageRefs.current[index] = el}
                      src={image}
                      alt=""
                      className="px-1 gallery-scroll-item"
                      onLoad={() => onImageLoad(index)}
                    />
                  ))}
                </div>
                <Arrow
                  direction="right"
                  style={{
                    ...arrowStyleBase,
                    position: 'absolute',
                    right: '0.2em',
                    visibility: `${currentImageIndex < images.length - 1 ? 'visible' : 'hidden'}`,
                  }}
                  onClick={() => updateCurrentImageIndex(currentImageIndex + 1)}
                />
              </p>
            </div>
          )
          : (
            <div className={`${className} columns`}>
              <div className="column is-7">
                <figure className="image is-5by4">
                  <img
                    src={images[currentImageIndex]}
                    style={{ height: CURRENT_IMG_HEIGHT }}
                    className="is-clickable"
                    onClick={() => setShowModal(true)}
                    onKeyUp={() => setShowModal(true)}
                    alt=""
                  />
                </figure>
              </div>
              <div className="column is-2">
                <div
                  className="is-vertical"
                  style={{
                    overflowY: 'auto',
                    height: CURRENT_IMG_HEIGHT,
                  }}
                >
                  {imageTiles}
                </div>
              </div>
            </div>
          )
      }
    </>
  );
};

export default ImageGallery;
