/* eslint-disable react/prop-types */
import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { apiBaseUrl, isFunction } from '../utils';

const addImageUploadListener = (inputElement, onStartProcessing, onImagesProcessed) => {
  if (!isFunction(onImagesProcessed)) return null;
  const fn = () => {
    if (isFunction(onStartProcessing)) onStartProcessing();
    if (!inputElement.files || inputElement.files.length <= 0) {
      onImagesProcessed([]);
      return;
    }
    Promise.all(Array.prototype.map.call(inputElement.files, (imageFile) => {
      const formData = new FormData();
      formData.append('image', imageFile);
      return axios({
        method: 'post',
        url: `${apiBaseUrl}/images/upload`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    })).then((responses) => {
      onImagesProcessed(responses.map((response) => response.data.src));
    }).catch((err) => console.error(err));
  };
  inputElement.addEventListener('change', fn);
  return fn;
};

const ImageInput = (props) => {
  const { onStartProcessing, onImagesProcessed, children } = props;
  const { multiple } = props;
  const uploadRef = useRef(null);
  const handlePick = () => uploadRef.current && uploadRef.current.click();
  useEffect(() => {
    const listener = addImageUploadListener(
      uploadRef.current,
      onStartProcessing,
      onImagesProcessed,
    );
    return listener ? () => uploadRef.current?.removeEventListener('change', listener) : null;
  }, [uploadRef, onStartProcessing, onImagesProcessed]);
  return (
    <div
      role="button"
      onClick={handlePick}
      onKeyPress={null}
      style={{ cursor: 'pointer' }}
      tabIndex={0}
    >
      <input ref={uploadRef} type="file" accept="image/*" style={{ display: 'none' }} multiple={multiple} />
      {children}
    </div>
  );
};

export default ImageInput;
