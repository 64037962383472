/* eslint-disable react/prop-types */
import React from 'react';

const ARROW_CLASSES = 'disable-hover-transform is-clickable has-text-primary-light fa is-size-2 fa-chevron-circle-';

const Arrow = (props) => {
  const { direction, style, onClick } = props;

  return (
    <i
      className={`${ARROW_CLASSES}${direction}`}
      style={{ ...style, textShadow: '2px 2px 5px #494949' }}
      onClick={onClick}
      onKeyUp={onClick}
      role="button"
      aria-label={direction}
      tabIndex={0}
    />
  );
};

export default Arrow;
