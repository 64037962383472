/* eslint-disable react/prop-types */
import React from 'react';

const Pagination = (props) => {
  const { numPages, currentPageIndex, setCurrentPageIndex } = props;
  const pageIndices = [0];
  if (currentPageIndex > 0) {
    if (currentPageIndex > 2) {
      pageIndices.push(null);
      pageIndices.push(currentPageIndex - 1);
    } else {
      for (let ii = 1; ii < currentPageIndex; ii += 1) {
        pageIndices.push(ii);
      }
    }
    pageIndices.push(currentPageIndex);
  }
  if (numPages > (currentPageIndex + 1)) {
    if (numPages > (currentPageIndex + 3)) {
      pageIndices.push(currentPageIndex + 1);
      pageIndices.push(null);
      pageIndices.push(numPages - 1);
    } else {
      for (let ii = currentPageIndex + 1; ii < numPages; ii += 1) {
        pageIndices.push(ii);
      }
    }
  }
  return (
    <div className="level is-mobile is-centered">
      {numPages > 1 && (
        pageIndices.map((key) => (
          key !== null ? (
            <button
              key={key}
              className={`button mx-2 ${key === currentPageIndex ? 'is-primary' : ''}`}
              type="button"
              onClick={() => (key !== currentPageIndex) && setCurrentPageIndex(key)}
            >
              {`${key + 1}`}

            </button>
          )
            : '...'
        ))
      )}
    </div>
  );
};

export default Pagination;
