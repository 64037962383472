/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import ImageInput from './ImageInput';
import UploadableImage from './UploadableImage';

const VariantForm = (props) => {
  const {
    onSave, onCancel, variant, isColorVariant,
    disableStock, disableImage,
  } = props;
  const [name, setName] = useState('');
  const [additionalCost, setAdditionalCost] = useState(null);
  const [stock, setStock] = useState(null);
  const [image, setImage] = useState(null);
  const [nameEmpty, setNameEmpty] = useState(false);
  useEffect(() => {
    if (variant) {
      if (!isColorVariant) setName(variant.name);
      if (variant.additionalCost) setAdditionalCost(variant.additionalCost);
      if (variant.stock != null) setStock(variant.stock);
      if (variant.image) setImage(variant.image);
    }
  }, []);
  const handleSave = () => {
    let hasError = false;
    if (!isColorVariant) {
      if (!name) {
        setNameEmpty(true);
        hasError = true;
      }
    }
    if (hasError) {
      return;
    }
    if (isColorVariant) {
      onSave({
        ...variant,
        additionalCost: additionalCost ? Number(additionalCost) : null,
        stock: stock ? Number(stock) : null,
        image,
      });
    } else {
      onSave({
        ...variant,
        name,
        additionalCost: additionalCost ? Number(additionalCost) : null,
        stock: stock ? Number(stock) : null,
        image,
      });
    }
  };
  const handleNameInput = (e) => {
    setNameEmpty(false);
    setName(e.target.value);
  };
  return (
    <div className="py-2 px-4">
      {
        !isColorVariant
      && (
      <div className="field">
        <label className="label is-size-7">Variant Name: </label>
        <div className="control">
          <input
            className="input is-size-7"
            type="text"
            name="variantname"
            placeholder="Enter Variant Name"
            onChange={handleNameInput}
            value={name}
          />
        </div>
        {nameEmpty && <p className="help is-danger">name cannot be empty</p>}
      </div>
      )
      }
      {
        additionalCost != null ? (
          <div className="field">
            <label className="label is-size-7">Additional Cost: </label>
            <div className="control">
              <input
                className="input is-size-7"
                type="number"
                placeholder="Price (CAD)"
                name="price"
                min="0"
                value={additionalCost}
                onChange={(e) => setAdditionalCost(e.target.value)}
              />
            </div>
            <button type="button" className="is-size-7 button is-ghost" onClick={() => setAdditionalCost(null)}>
              Remove
            </button>
          </div>
        ) : (
          <button type="button" className="is-size-7 button is-ghost" onClick={() => setAdditionalCost(0)}>
            Add additional cost
          </button>
        )
      }
      {
        stock != null ? (
          <div className="field">
            <label className="label is-size-7">Quantity: </label>
            <div className="control">
              <input
                className="input is-size-7"
                type="number"
                placeholder="Price (CAD)"
                name="price"
                min="0"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
              />
            </div>
            <button type="button" className="is-size-7 button is-ghost" onClick={() => setStock(null)}>
              Remove
            </button>
          </div>
        ) : !disableStock && (
          <button type="button" className="is-size-7 button is-ghost" onClick={() => setStock(0)}>
            Add quantity
          </button>
        )
      }
      {
        image != null ? (
          <UploadableImage
            initialSrc={image}
            onChangeSrc={setImage}
            onDelete={() => setImage(null)}
          />
        ) : !disableImage && (
          <ImageInput onImagesProcessed={(images) => setImage(images[0])}>
            <button type="button" className="is-size-7 button is-ghost">
              Add image
            </button>
          </ImageInput>
        )
      }

      <div className="field is-grouped pt-2">
        <div className="control">
          <button type="button" onClick={handleSave} className="button is-link">Save</button>
        </div>
        <div className="control">
          <button type="button" onClick={onCancel} className="button is-link is-light">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default VariantForm;
