import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

const SearchBox = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const { pathname } = useLocation();
  const [showDelete, setShowDelete] = useState(false);
  useEffect(() => {
    if (!pathname.startsWith('/search')) setSearchTerm('');
  }, [pathname]);
  const performSearch = () => {
    // TODO sanitize input, we can require length to be at least 3 characters or 0
    navigate(`/search?q=${searchTerm}`);
  };
  const handleSearchInput = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    if (!showDelete && e.target.value) {
      setShowDelete(true);
    }
    if (showDelete && !e.target.value) {
      setShowDelete(false);
    }
  };
  const handleKeyEvent = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      performSearch(false);
    }
  };
  const handleClickDelete = () => {
    setSearchTerm('');
    setShowDelete(false);
  };
  return (
    <div className="control has-icons-right">
      <input className="input is-rounded" type="search" placeholder="Search" onChange={handleSearchInput} value={searchTerm} onKeyPress={handleKeyEvent} />

      <span className="icon is-small is-right pt-2 is-clickable">
        {showDelete ? (
          <i
            className="fa fa-times"
            onClick={handleClickDelete}
            onKeyPress={(e) => e.key === 'Enter' && handleClickDelete()}
            role="button"
            aria-label="delete"
            tabIndex={0}
          />
        ) : (
          <svg
            className=""
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path
              fill="#e9d0f7"
              d="M23.822
                20.88l-6.353-6.354c.93-1.465 1.467-3.2
                1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468
                4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0
                3.421-.487 4.839-1.333l6.396 6.396
                3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938
                3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94
                5.939-3.274 0-5.939-2.664-5.939-5.939z"
            />
          </svg>
        )}
      </span>
    </div>
  );
};

export default SearchBox;
